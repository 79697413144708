<template>
  <div class="dashboard">
    <va-card :title="$t('emAtraso.title') + ` - ${filteredData.length} operações encontradas`">
      <div class="row align--center mb-1">
        <div class="flex xs12 sm6">
          <va-input
            class="ma-0"
            :value="term"
            :placeholder="$t('tables.searchByName')"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
        <div class="flex xs12 md3 offset--md3">
          <va-select
            :options="perPageOptions"
            v-model="perPage"
            label="Itens por página"
          />
        </div>
        <va-checkbox
          v-model="filtro"
          option="dosSocios"
          array-value="Sócio/Avalista"
          label="Sócios"
        />

        <va-checkbox
          v-model="filtro"
          option="dadosPrincipais"
          array-value="Principal"
          label="Dados Principais"
        />

        <va-checkbox
          v-model="filtro"
          option="financeiro"
          array-value="Financeiro"
          label="Financeiro"
        />

        <va-checkbox
          v-model="filtro"
          array-value="Ficha PJ"
          label="Ficha PJ"
        />

        <va-checkbox
          v-model="apenasCelulares"
          label="Apenas números iniciados com 9"
        />

        <va-checkbox
          v-model="naoRepetirTelefones"
          label="Não exibir telefones repetidos"
        />

      </div>

      <va-data-table
        :per-page="perPage"
        :fields="mode ? detailedFields : fields"
        :data="filteredData"
        :loading="loading"
        hoverable
      >

        <template slot="razaoSocial" slot-scope="props">
          {{props.rowData.razaoSocial}}
        </template>

      </va-data-table>
    </va-card>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import negativacoesService from '../../services/Cobranca/NegativacoesService'
import _ from 'lodash'

export default {
  components: {
  },
  data () {
    return {
      perPage: 15,
      perPageOptions: ['15', '25', '35', '50', '75', '100', '200', '300', '400', '500', '1000'],
      operacoes: [],
      apenasQuemNuncaFezAcordo: false,
      filtro: ['Principal', 'Sócio/Avalista', 'Ficha PJ', 'Financeiro'],
      loading: false,
      apenasCelulares: false,
      dadosPrincipais: true,
      fichaPj: true,
      financeiro: true,
      dosSocios: true,
      naoRepetirTelefones: false,
      term: null,
      mode: 0,
      ajuizamento: {
        add: false,
        emprestimoId: null,
        razaoSocial: null,
        numeroProcesso: null,
        link: null,
      },
    }
  },
  computed: {
    fields () {
      return [{
        name: '__slot:razaoSocial',
        title: 'Razão Social',
      }, {
        name: 'telefone',
        title: 'Telefone',
      },
      {
        name: 'nome',
        title: 'Nome',
      },
      {
        name: 'email',
        title: 'Email',
      },
      {
        name: 'tipo',
        title: 'Tipo',
      },
      {
        name: 'estaEmCobrancaExternaGlobal',
        title: 'Cob. Externa',
      },
      ]
    },
    modeOptions () {
      return [{
        value: 0,
        label: this.$t('dashboard.table.brief'),
      }]
    },
    filteredData () {
      let ret = null
      if (!this.term || this.term.length < 1) {
        ret = this.operacoes
      } else {
        ret = this.operacoes.filter(item => {
          return item.razaoSocial.toLowerCase().startsWith(this.term.toLowerCase())
        })
      }

      if (this.filtro.length) {
        ret = ret.filter(x => this.filtro.indexOf(x.tipo) >= 0)
      }

      if (this.apenasCelulares) {
        ret = ret.filter(x => x.telefone.match(/\d+/g).join('').substr(2).startsWith(9))
      }

      if (this.naoRepetirTelefones) {
        ret = _.uniqBy(ret, 'telefone')
      }
      return ret
    },
  },
  methods: {
    search: debounce(function (term) {
      this.term = term
    }, 400),
  },
  async mounted () {
    this.operacoes = (await negativacoesService.telefonesInadimplentes()).data.data
  },
}
</script>
